export { default as IC_audio } from "./ic_audio.svg";
export { default as IC_AI } from "./ic_ai.svg";
export { default as IC_Document } from "./ic_document.svg";
export { default as IC_Excel } from "./ic_excel.svg";
export { default as IC_File } from "./ic_file.svg";
export { default as IC_folder } from "./ic_folder.svg";
export { default as IC_img } from "./ic_img.svg";
export { default as IC_txt } from "./ic_txt.svg";
export { default as IC_zip } from "./ic_zip.svg";
export { default as IC_video } from "./ic_video.svg";
export { default as IC_word } from "./ic_word.svg";
export { default as IC_power_point } from "./ic_power_point.svg";
export { default as IC_pdf } from "./ic_pdf.svg";
